import React from "react";
import { AppsWrapper, BgOpacity, LoginView, WrapperBottom } from "./styled";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import logo from "../../assets/brand/cbimage.png";
import logoAlive from "../../assets/brand/Logo_AnT.png";
import MenuLanguage from "../menuLanguage";

const AppsPage = () => {
  const { t } = useTranslation();

  return (
    <AppsWrapper>
      <MenuLanguage />
      <div className="logo">
        <img src={logo} alt="" />
      </div>

      <div
        className="item blue"
        onClick={() => {
          setTimeout(() => {
            window.open("https://ncbsm.bmte.vn/", "_blank");
          }, 0);
        }}
      >
        <BgOpacity>
          <WrapperBottom>
            <div className="title">{t("app.breastfeeding")}</div>
            <LoginView>{t("common.login")}</LoginView>
          </WrapperBottom>
        </BgOpacity>
      </div>

      <div
        className="item yellow"
        onClick={() => {
          setTimeout(() => {
            window.open("https://nhsm.bmte.vn", "_blank");
          }, 0);
        }}
      >
        <BgOpacity>
          <WrapperBottom>
            <div className="title">{t("app.breastMilkBank")}</div>
            <LoginView>{t("common.login")}</LoginView>
          </WrapperBottom>
        </BgOpacity>
      </div>

      <div className="item green">
        <BgOpacity>
          <WrapperBottom>
            <div
              className="title"
              onClick={() => {
                setTimeout(() => {
                  window.open("https://dtts.bmte.vn/", "_blank");
                }, 0);
              }}
            >
              {t("app.nationalMinorities")}
            </div>

            <LoginView
              onClick={() => {
                setTimeout(() => {
                  window.open("https://dtts.bmte.vn/admin", "_blank");
                }, 0);
              }}
            >
              {t("common.login")}
            </LoginView>
          </WrapperBottom>
        </BgOpacity>
      </div>

      <div className="footer">
        <div>© {t("app.copyright")}</div>
        <div>{t("app.supportBuild")} </div>
        <img src={logoAlive} alt="" />
        <div>{t("app.image")}: Alive & Thrive</div>
      </div>
    </AppsWrapper>
  );
};

export default AppsPage;
