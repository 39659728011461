import { Button } from "antd/lib";
import styled from "styled-components";
import background1 from "../../assets/brand/img_1.JPG";
import background2 from "../../assets/brand/img_2.jpg";
import background3 from "../../assets/brand/img_3.jpeg";

export const IConLanguage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
export const Buttonanguage = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 85px;
`;
export const BgOpacity = styled.div`
  background-color: rgba(0, 0, 0, 0.15);
  width: 33vw;
  height: 33vw;
  position: absolute;
  margin-bottom: -10px;
  z-index: 2;
  display: flex;
  align-items: flex-end;

  text-align: center;
  color: #ffffff;
  justify-content: center;
  padding: 10px;
  :hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const LoginView = styled.div`
  border-radius: 100px;
  border: 1px solid white;
  width: fit-content;
  padding: 3px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  :hover {
    text-decoration: underline;
  }
`;
export const WrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AppsWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f5fff9;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .item {
    position: relative;
    width: 33vw;
    height: 33vw;
    font-size: 22px;
    box-shadow: 1px 2px 2px 1px #dfe6e9;
    line-height: 35px;
    font-weight: 700;
    cursor: pointer;
  }
  .title {
    height: 9vw;
    width: 429px;
    text-align: center;
    white-space: pre-line;
  }
  .blue {
    background-image: url(${background1});
    background-position: 100%;
    background-size: cover;
  }
  .yellow {
    background-image: url(${background2});
    background-size: cover;
    margin: 0 4px;
  }
  .green {
    background-image: url(${background3});
    background-position: 40%;
    background-size: cover;
  }
  .hover-image-1 {
    visibility: hidden;
    background-color: #000;
    width: 33vw;
    height: 33vw;
    position: absolute;
    margin-bottom: -10px;
    opacity: 0.3;
    z-index: 2;
  }
  .hover-image-2 {
    visibility: hidden;
    background-color: #000;
    width: 33vw;
    height: 33vw;
    position: absolute;
    margin-bottom: -10px;
    opacity: 0.3;
    z-index: 2;
  }
  .hover-image-3 {
    visibility: hidden;
    background-color: #000;
    width: 33vw;
    height: 33vw;
    position: absolute;
    margin-bottom: -10px;
    opacity: 0.3;
    z-index: 2;
  }
  .footer {
    width: 100vw;
    height: 60px;
    background-color: #ecf0f1;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    img {
      width: auto;
      height: 40px;
      margin-right: 60px;
      margin-left: 12px;
    }
    div:first-child {
      margin-right: 60px;
    }
  }
`;
